<template>
	<div class="b-page-wrapper administrator">
		<div class="b-head-block b-head-block--link">
			<div class="b-container">
				<div class="b-head-block__wrapper">
					<router-link class="b-link b-link--header" :to="'/Companies'" title="">
						<iconAngleleftSvg class="b-icon b-icon--header icon-angle-left" />
						<span>Назад</span>
					</router-link>
					<h1 class="b-title b-title--h1">{{ !$route.params.id ? "Добавление МПНКО" : "Редактирование МПНКО" }}
					</h1>
				</div>
			</div>
		</div>
		<div class="b-container">
			<div class="b-window">
				<div class="b-form" action="/">
					<div class="b-form__first-lavel">
						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="role">Наименование*
								</label>
							</div>
							<div class="b-form__right">
								<div class="b-select">
									<v-select
										:loading="loading"
										:placeholder="obj.roleId == 1 || !flagName && !loading ? 'Для разблокировки введите ИНН' : obj.roleId == 1 || !flagName && loading ? 'Идет получение организации' : 'Выберите организацию'"
										v-model="obj.name"
										:disabled="obj.roleId == 1 || !flagName"
										id="role" name="role"
										:class="{'border': flagNameOrg}"
										:options="organizName"
										:reduce="item => item.n"
									  	label="n">
									</v-select>
									<div class="b-icon">
										<iconAngleDownSvg  v-if="!loading" class="b-icon--down icon-angle-down" />
									</div>
									<div v-if="loading" class="b-icon">
										<RingLoader class="ring-load-loading" color="#04092a" :size="16"/>
									</div>
								</div>

							</div>
						</div>
						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="name">ИНН*
								</label>
							</div>
                     <div class="b-form__right">
								<div class="b-input">
									<input @change="pasteName(obj)" v-model="obj.inn" :disabled="obj.roleId == 1" :class="{'border': flagInn}" class="b-input__input-field" id="name" type="number" placeholder="Введите ИНН" name="name"/>
									<i class="b-icon b-icon--check icon-check"></i><i class="b-icon b-icon--close icon-close"></i>
								</div>
							</div>
						</div>
						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="name">Адрес
								</label>
							</div>
							<div class="b-form__right">
								<div class="b-input">
									<input v-model="obj.address" required :disabled="obj.roleId == 1" class="b-input__input-field" id="name" type="text" placeholder="Введите адрес" name="name"/>
									<i class="b-icon b-icon--check icon-check"></i><i class="b-icon b-icon--close icon-close"></i>
								</div>
							</div>
						</div>
						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="phone">Телефон
								</label>
							</div>
							<div class="b-form__right">
								<div class="b-input">
                           <input v-model="obj.phone" class="b-input__input-field" id="phone" type="tel" placeholder="Введите номер телефона" maxlength="15"  v-phone/>
                           <i class="b-icon b-icon--check icon-check"></i><i class="b-icon b-icon--close icon-close"></i>
								</div>
							</div>
						</div>
						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="email-developer">E-mail
								</label>
							</div>
							<div class="b-form__right">
								<div class="b-input">
									<input v-model="obj.email" class="b-input__input-field" id="email-developer" type="email" placeholder="Введите e-mail пользователя" name="email-developer"/>
									<i class="b-icon b-icon--check icon-check"></i><i class="b-icon b-icon--close icon-close"></i>
								</div>
							</div>
						</div>
					</div>
					<!-- <ul style="margin-bottom: 20px;" v-for="(item,index) in errors" :key="index">
							<li>{{ item}}</li>
						</ul> -->
					<div class="b-form__group b-form__group--button">
						<button @click="save(obj)" class="b-button b-button--fill b-button--full">Сохранить</button>

						<router-link class="b-button b-button--empty b-button--full" :to="'/Companies'" title="Отменить">Отменить</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import iconAngleDownSvg from "../../assets/icons/svg/angle-down.svg"
import iconAngleleftSvg from "../../assets/icons/svg/angle-left.svg"
import { RingLoader } from '@saeris/vue-spinners'
import {mapActions} from 'vuex'

export default {

	components: {
		iconAngleleftSvg,
		iconAngleDownSvg,
		RingLoader
	},
	name: 'icn',
	data: () => ({
		disabledInpRole: false,
		obj: {
         name: "",
			email: "",
			inn: null,
			address: "",
			phone: "",
		},
		errors:[],
		loading: false,
		organizName:[],
		flagName: false,
		flagInn: false,
		flagNameOrg: false,
		listCompanies: [],
	}),
	methods: {
		...mapActions({
			apiAll: 'api/apiAll'
		}),
		save(obj) {
			this.errors = []
			this.flagInn = false
			this.flagNameOrg = false
			if (obj.name && obj.inn) {
				this.apiAll({type: `${'post'}`, url: `api/Companies/CheckFns`, id:undefined, obj: obj})
				.then(res => {
					console.log(res);
					if(res.data.isSuccess) {
						console.log('issuccess');
						if (obj.name || obj.inn) {
							this.apiAll({type: `${this.$route.params.id ? 'put' : 'post'}`, url: `api/Companies`, id: this.$route.params.id ? obj.id : undefined, obj: obj})
							.then(res => {
								console.log(res.data);
								if(res.status == 201 || res.status == 200 ) {
									this.$notify({ group: 'foo', duration: 5000, type: 'success', title: 'Успешно', text: `${this.$route.params.id ? 'Организация успешно редактирована' : 'Организация успешно создана'}` })
									this.obj = { email: "", phone: "", name: "", address: "", inn: "" }
									this.$router.push(`${'/Companies'}`)
								} else {
									this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при создании организации' })
								}
							})
							.catch(error => {
								if(error) {
									if(error.status == 409){
										this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка при создании', text: 'Организация с данным email уже существует в Системе' })
									} else {
										this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка при создании', text: 'Заполните все поля...' })
									}
								}
				})
			} else {
				this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Заполните обязательные поля', text: 'Заполните все поля...' })
			}
					} else {
						this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при создании организации' })
					}
				})
			}
			else{
				if(!obj.inn) this.flagInn = true
				if(!obj.name) this.flagNameOrg = true
				// this.errors.push('Заполните обязательные поля')
				this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Заполните обязательные поля', text: 'Заполните все поля...' })

			}

		},
		pasteName(obj){
			obj.name = ""
			if (obj.inn.length >= 10) {
				if (obj.inn) this.flagInn = false
				this.loading = true
				this.flagName = false
					this.apiAll({type: `${'post'}`, url: `api/Companies/CheckFns`, id:undefined, obj: {...obj, inn: `${obj.inn}`}})
					.then(res => {
						this.flagName = true
						this.organizName = res.data.data.rows
						this.obj.name = res.data.data.rows[0].n
						if (this.obj.name){
							this.flagNameOrg = false
							this.loading = false
						}


					})
					.catch(error => {
						console.log(error);
								if(error) {
									this.loading = false
										this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Не нашли организацию с указанным ИНН' })
								}
				}
			)
			}

		}
	},
	created() {
      if(this.$route.params.id){
         this.apiAll({type: 'get', url: `api/Companies/${this.$route.params.id}`, id: undefined})
		.then(res => {
			this.obj = Object.assign({}, res.data)
		})
      }
		this.apiAll({type: 'get', url: `api/Companies`, id: undefined}).then(res => {
			this.listCompanies = res.data
			this.listCompanies.sort(function (a, b) {
			  if (a.name > b.name) return 1
			  if (a.name < b.name) return -1
			  return 0
			});
		})
	}

}
</script>

<style>
@import '../../style/stylePages.css';

.b-select__select-field[disabled] {
    color: black!important;
	cursor: not-allowed;
}

.vs__dropdown-toggle {
	border-radius: 0;
	border: 1px solid #e5e5eb;
}
.border{
	border: 1px solid red;
}

.vs__actions {
	display: none;
}
.v-select input::placeholder {
   font-size: 16px;
	font-family: sans-serif;
	color: gray;
}
::-webkit-input-placeholder { font-size: 16px; font-family: sans-serif;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>
